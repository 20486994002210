.footerContact {
  background-color: #27ae60;
  padding: 40px 0;
  color: #fff;
}
.hm{
  margin-left: 10px;
  margin-right: 10px;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}
.contact{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.contact-card{
  margin: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-detail{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
footer img {
  width: 150px;
  border-radius: 20px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: grey;}
footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
}
footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}
.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.custom-line {
  border: none;
  height: 2px; 
  background-color: white; 
  margin: 20px 0; 
}
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
